<template>
  <div class="nearby-places--field mb-2" :class="{ 'has-error': validation.hasError('phone') }">
    <span class="mr-2">{{ index + 1 }}. </span>
    <v-text-field
      id="phoneField"
      class="mr-2"
      :value="phone"
      @input="inputPhone"
      type="text"
      name="contacts[]"
      :placeholder="`${$t('project.contact')} ${index + 1}`"
      outlined
      hide-details
    />
    <v-btn color="error" @click="removeContact">
      <v-icon v-text="'mdi-trash-can-outline'" />
    </v-btn>
    <span class="val-error" v-if="validation.hasError('phone')">{{
      validation.firstError('phone')
    }}</span>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

export default {
  name: 'contact-field',
  props: ['phone', 'index', 'contactLength'],
  validators: {
    phone(value) {
      return Validator.value(value === undefined ? null : value)
        .required(this.$i18n.t('errors.addProject.projectContact.required'))
        .maxLength(100, this.$i18n.t('errors.addProject.projectContact.max', { maxChar: 100 }));
    },
  },
  methods: {
    inputPhone(input) {
      this.$store.commit('project/form/INPUT_CONTACT_PHONE', {
        phone: input,
        index: this.index,
      });
    },
    removeContact() {
      this.$store.commit('project/form/REMOVE_CONTACT', this.index);
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
